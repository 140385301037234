<div id="business-unit-assign-container">
  <p-blockUI [blocked]="loading === true"></p-blockUI>
  @if (ifDeviceMatches([DeviceSize.LG, DeviceSize.XL])) {
    <div
      class="ui-g ui-fluid"
      >
      <div style="text-align: right">
        <div style="text-align: right">
          <app-progress-spinner [enabled]="saving"></app-progress-spinner>
        </div>
      </div>
    </div>
  }
  <app-progress-spinner [enabled]="!elements"></app-progress-spinner>
  <div class="content">
    <div class="button-wrapper group content-header">
      <button
        pButton
        class="refresh-button width-twenty"
        icon="fa fa-fw fa-refresh"
        (click)="refresh()"
      ></button>
    </div>

    @if (ifDeviceMatches([DeviceSize.LG, DeviceSize.XL])) {
      <div
        [style.display]="!elements ? 'none' : ''"
        >
        <app-general-grid
          [values]="elements"
          [columns]="columns"
          [loading]="loading"
          [deviceSize]="getDeviceSize()"
          [totalRecords]="totalRecords"
          [filterable]="true"
          [screenName]="screenName"
          [rows]="rows"
          [lazy]="true"
          (lazyLoad)="lazyLoad($event)"
          [includeExportCSV]="false"
          (changeViewEvent)="changeViewEvent($event)"
          scrollHeight="calc(100vh - 325px)"
          [usePaginator]="false"
          [alwaysShowPaginator]="false"
          [rowsPerPageOptions]="[50, 100, 200]"
          #grid
          >
        </app-general-grid>
      </div>
    }

    <p-dialog
      [header]="dialogHeader"
      [(visible)]="displayDialog"
      showEffect="fade"
      [modal]="true"
      (onHide)="dialogClosed()"
      [transitionOptions]="'0ms'"
      [style]="{ width: '1000px' }"
      >
      <form [formGroup]="roleBusinessUnitForm">
        <div>
          <div class="no-padding modal-input">
            <div class="label">{{ "ADMIN.LABEL.NAME" | translate }}:</div>
            <div>
              <input type="text" pInputText formControlName="name" />
            </div>
          </div>
          <div style="width: 100%">
            <p-pickList
              [source]="sourcePicklist"
              [target]="targetPicklist"
              targetHeader="Assigned Business Units"
              [dragdrop]="false"
              sourceHeader="Available Business Units"
              [responsive]="true"
              [showSourceControls]="false"
              [showTargetControls]="false"
              [sourceStyle]="{
                height: '500px',
                'box-shadow': 'rgba(0, 0, 0, 0.1) 0px 4px 12px'
              }"
              [targetStyle]="{
                height: '500px',
                'box-shadow': 'rgba(0, 0, 0, 0.1) 0px 4px 12px'
              }"
              >
              <ng-template let-businessunit pTemplate="item">
                <div>
                  <div>
                    <h5>{{ businessunit.name }}</h5>
                  </div>
                </div>
              </ng-template>
            </p-pickList>
          </div>
          <div style="margin-top: 8px">
            @if (canEdit) {
              <div class="button-wrapper">
                <button
                  pButton
                  label="{{ 'COMMON.LABEL.BUTTONS.SAVE' | translate }}"
                  icon="fa fa-fw fa-save"
                  (click)="saveRole()"
                  [disabled]="!this.roleBusinessUnitForm.valid"
                ></button>
              </div>
            }
          </div>
        </div>
      </form>
    </p-dialog>
  </div>

  @if (ifDeviceMatches([DeviceSize.XS, DeviceSize.SM, DeviceSize.MD])) {
    <div>
      <p-message
        severity="info"
        text="{{ 'COMMON.LABEL.RESOLUTION_NOT_SUPPORTED' | translate }}"
      ></p-message>
    </div>
  }
</div>
