import { Injectable } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { isNullOrEmptyArray } from '../core/functions/common-functions';
import { DataTableInput } from '../model/admin/data-table-input';
import { EmailGroup } from '../model/admin/email-group';
import { EmailGroupInput } from '../model/admin/email-group-input';
import { EmailGroupMember } from '../model/admin/email-group-member';
import { EmailGroupMemberInput } from '../model/admin/email-group-member-input';
import { EmailRequest } from '../model/admin/email-request';
import { DirtyStatus } from '../model/common/dirty-status';
import { Contact } from '../model/contacts/contact';
import { DistrictDataTableInput } from '../model/admin/district-data-table-input';
import { MitigationDataTableInput } from '../model/admin/mitigation-data-table-input';
import { CompanyDataTableInput } from '../model/admin/company-data-table-input';
import { MOCEventCatInput } from '../model/admin/moc-event-cat-input';
import { NoteTypeInput } from '../model/admin/note-type-input';
import { MonLogTypeInput } from '../model/admin/mon-log-type-input';
import { UpdateRoleInput } from '../model/admin/update-role-input';
import { Claim, UpdateClaimInput } from '../model/admin/claim';
import { BusinessUnit } from 'src/app/model/admin/business-unit';
import { JobRoleDataTableInput } from '../model/admin/job-role-data-table-input';
import { isNullOrUndefined } from '../utils/utils';
import { Role } from '../model/admin/role';

@Injectable({
  providedIn: 'root',
})
export class AdminBuilderService {
  constructor() {}

  buildEmailGroupInput(
    emailGroup: EmailGroup,
    targetBusinessUnits: BusinessUnit[]
  ): EmailGroupInput {
    const assignedUnits: BusinessUnit[] = !isNullOrUndefined(
      targetBusinessUnits
    )
      ? targetBusinessUnits
      : [];
    if (!isNullOrUndefined(emailGroup)) {
      if (emailGroup.dirtyStatus === DirtyStatus.NEW) {
        return {
          id: uuid(),
          name: emailGroup.name,
          enabled: emailGroup.enabled,
          EmailGroupMembers: [],
          dirtyStatus: emailGroup.dirtyStatus,
          expectedVersion: 0,
          businessUnits: assignedUnits.map((m) => ({
            id: m.id,
            name: m.name,
          })),
        };
      } else {
        const members: EmailGroupMember[] = !isNullOrUndefined(
          emailGroup.EmailGroupMembers
        )
          ? emailGroup.EmailGroupMembers
          : [];
        return {
          id: emailGroup.id,
          name: emailGroup.name,
          enabled: emailGroup.enabled,
          EmailGroupMembers: members.map((m) => ({
            id: m.id,
            emailGroupId: emailGroup.id,
            address: m.address,
            expectedVersion: m.version,
            dirtyStatus: m.dirtyStatus,
          })),
          dirtyStatus: emailGroup.dirtyStatus,
          expectedVersion: emailGroup.version,
          businessUnits: assignedUnits.map((m) => ({
            id: m.id,
            name: m.name,
          })),
        };
      }
    } else {
      return null;
    }
  }

  buildGraphQLEmailGroup(id, input: EmailGroupInput): EmailGroup {
    if (!isNullOrUndefined(input)) {
      const members: EmailGroupMemberInput[] = !isNullOrEmptyArray(
        input.EmailGroupMembers
      )
        ? input.EmailGroupMembers
        : [];
      const retVal = {
        __typename: 'EmailGroup',
        id: id,
        name: input.name,
        enabled: input.enabled,
        EmailGroupMembers: members.map((m) => ({
          __typename: 'EmailGroupMember',
          id: m.id,
          address: m.address,
          createdBy: null,
          updater: null,
          createdAt: null,
          updatedAt: null,
          version: m.expectedVersion,
        })),
        updater: null,
        dirtyStatus: input.dirtyStatus,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        version: input.expectedVersion,
      };
      return retVal;
    } else {
      const retVal = {
        __typename: 'EmailGroup',
        id: id,
        name: null,
        enabled: null,
        EmailGroupMembers: [],
        dirtyStatus: null,
        createdBy: null,
        updater: null,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        version: null,
      };
      return retVal;
    }
  }

  buildEmailGroupContact(contact: Contact): any {
    if (!isNullOrUndefined(contact)) {
      return {
        fullName: contact.fullName,
        address: contact.adAccountName,
      };
    }
  }

  buildNewEmailGroupMember(address: string): EmailGroupMember {
    const retVal: EmailGroupMember = {
      id: uuid(),
      address: address,
      dirtyStatus: DirtyStatus.NEW,
      version: 0,
    };
    return retVal;
  }

  buildEmailRequest(
    id: any,
    toList: string,
    bccList: string,
    emailNotes: string
  ): EmailRequest {
    return {
      id: id,
      toList: toList,
      bccList: bccList,
      emailNotes: emailNotes,
    };
  }

  buildDataTableInput(input: any, isNew: boolean): DataTableInput {
    if (!isNullOrUndefined(input)) {
      return {
        id: isNew ? uuid() : input.id,
        name: input.name,
        enabled: input.enabled,
        expectedVersion: isNew ? 0 : input.version,
        dirtyStatus: input.dirtyStatus,
      };
    } else {
      return null;
    }
  }

  buildJobRoleDataTableInput(
    input: any,
    isNew: boolean,
    shiftGroups: string[]
  ): JobRoleDataTableInput {
    if (!isNullOrUndefined(input)) {
      return {
        id: isNew ? uuid() : input.id,
        name: input.name,
        shiftGroups: shiftGroups,
        enabled: input.enabled,
        expectedVersion: isNew ? 0 : input.version,
        dirtyStatus: input.dirtyStatus,
      };
    } else {
      return null;
    }
  }

  buildDistrictDataTableInput(
    input: any,
    isNew: boolean
  ): DistrictDataTableInput {
    if (!isNullOrUndefined(input)) {
      const retVal: DistrictDataTableInput = {
        id: isNew ? uuid() : input.id,
        name: input.name,
        sortOrder: input.sortOrder,
        enabled: input.enabled,
        expectedVersion: isNew ? 0 : input.version,
        dirtyStatus: input.dirtyStatus,
      };

      return retVal;
    } else {
      return null;
    }
  }

  buildMitigationDataTableInput(
    input: any,
    isNew: boolean
  ): MitigationDataTableInput {
    if (!isNullOrUndefined(input)) {
      return {
        id: isNew ? uuid() : input.id,
        name: input.name,
        sortOrder: input.sortOrder,
        shiftGroupId: isNew ? input.shiftGroup : input.ShiftGroup.id,
        enabled: input.enabled,
        expectedVersion: isNew ? 0 : input.version,
        dirtyStatus: input.dirtyStatus,
      };
    }
  }

  buildCompanyDataTableInput(
    input: any,
    isNew: boolean
  ): CompanyDataTableInput {
    if (!isNullOrUndefined(input)) {
      return {
        id: isNew ? uuid() : input.id,
        name: input.name,
        abbreviation: input.abbreviation,
        enabled: input.enabled,
        expectedVersion: isNew ? 0 : input.version,
        dirtyStatus: input.dirtyStatus,
      };
    } else {
      return null;
    }
  }

  buildMOCEventCatInput(
    input: any,
    isNew: boolean,
    targetBusinessUnits: BusinessUnit[]
  ): MOCEventCatInput {
    if (!isNullOrUndefined(input)) {
      const assignedUnits: BusinessUnit[] = !isNullOrUndefined(
        targetBusinessUnits
      )
        ? targetBusinessUnits
        : [];
      return {
        id: isNew ? uuid() : input.id,
        name: input.name,
        // shiftGroupId: isNew ? input.shiftGroup : input.ShiftGroup.id,
        enabled: input.enabled,
        expectedVersion: isNew ? 0 : input.version,
        dirtyStatus: input.dirtyStatus,
        businessUnits: assignedUnits.map((m) => ({
          id: m.id,
          name: m.name,
        })),
      };
    }
  }

  buildNoteTypeInput(
    input: any,
    isNew: boolean,
    targetBusinessUnits: BusinessUnit[]
  ): NoteTypeInput {
    if (!isNullOrUndefined(input)) {
      const assignedUnits: BusinessUnit[] = !isNullOrUndefined(
        targetBusinessUnits
      )
        ? targetBusinessUnits
        : [];
      return {
        id: isNew ? uuid() : input.id,
        name: input.name,
        enabled: input.active,
        expectedVersion: isNew ? 0 : input.version,
        dirtyStatus: input.dirtyStatus,
        categories: input.categories,
        createdBy: input.createdBy,
        updatedBy: input.updatedBy,
        businessUnits: assignedUnits.map((m) => ({
          id: m.id,
          name: m.name,
        })),
      };
    }
  }

  buildMonLogTypeInput(
    input: any,
    isNew: boolean,
    targetBusinessUnits: BusinessUnit[]
  ): MonLogTypeInput {
    if (!isNullOrUndefined(input)) {
      const assignedUnits: BusinessUnit[] = !isNullOrUndefined(
        targetBusinessUnits
      )
        ? targetBusinessUnits
        : [];
      return {
        id: isNew ? uuid() : input.id,
        name: input.name,
        enabled: input.enabled,
        expectedVersion: isNew ? 0 : input.version,
        dirtyStatus: input.dirtyStatus,
        businessUnits: assignedUnits.map((m) => ({
          id: m.id,
          name: m.name,
        })),
        requiresCRER: input.requiresCRER,
      };
    } else {
      return null;
    }
  }

  buildRoleClaimInput(input: any, targetClaims: Claim[]): UpdateRoleInput {
    if (!isNullOrUndefined(input)) {
      const members: Claim[] = !isNullOrUndefined(targetClaims)
        ? targetClaims
        : [];
      return {
        id: input.id,
        claims: members.map((m) => ({
          id: m.id,
          name: m.name,
        })),
        businessUnits: null,
      };
    } else {
      return null;
    }
  }

  buildClaimRoleInput(input: any, targetRoles: Role[]): UpdateClaimInput {
    if (!isNullOrUndefined(input)) {
      const roles: Role[] = !isNullOrUndefined(targetRoles) ? targetRoles : [];
      return {
        id: input.id,
        roles: roles.map((m) => ({
          id: m.id,
          name: m.name,
        })),
      };
    } else {
      return null;
    }
  }

  buildRoleBusinessUnitsInput(
    input: any,
    targetBusinessUnits: BusinessUnit[]
  ): UpdateRoleInput {
    if (!isNullOrUndefined(input)) {
      const assignedUnits: BusinessUnit[] = !isNullOrUndefined(
        targetBusinessUnits
      )
        ? targetBusinessUnits
        : [];
      return {
        id: input.id,
        claims: null,
        businessUnits: assignedUnits.map((m) => ({
          id: m.id,
          name: m.name,
        })),
      };
    } else {
      return null;
    }
  }
}
