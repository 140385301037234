import { NoteTypeInput } from 'src/app/model/admin/note-type-input';
import { NoteType } from 'src/app/model/crm/note-type';
import { isNullOrUndefined } from 'src/app/utils/utils';

export function isValidNoteTypeInput(
  input: NoteTypeInput,
  types: NoteType[]
): any {
  if (!isNullOrUndefined(types) && !isNullOrUndefined(input)) {
    const isDuplicate = types.find(
      (c) => c.name === input.name && c.id !== input.id
    );
    if (!isNullOrUndefined(isDuplicate)) {
      return 'CRM.MESSAGES.ERROR.DUPLICATE_TYPE';
    }
  }

  return null;
}
