<div id="company-container">
  <p-blockUI [blocked]="loading"></p-blockUI>

  @if (ifDeviceMatches([DeviceSize.LG, DeviceSize.XL]) && hasLoaded) {
    <div
      style="text-align: right"
      >
      <div style="text-align: right">
        <app-progress-spinner [enabled]="saving"></app-progress-spinner>
      </div>
      <div class="button-wrapper group header">
        @if (canCreate) {
          <button
            pButton
            class="width-eighty"
            label="{{ 'COMMON.LABEL.BUTTONS.NEW' | translate }}"
            icon="fa fa-fw fa-plus-circle"
            (click)="newCompany()"
          ></button>
        }
        <button
          pButton
          class="refresh-button width-twenty"
          icon="fa fa-fw fa-refresh"
          (click)="refresh()"
        ></button>
      </div>
    </div>
  }

  <app-progress-spinner [enabled]="!elements"></app-progress-spinner>
  @if (ifDeviceMatches([DeviceSize.LG, DeviceSize.XL])) {
    <div
      [style.display]="!elements ? 'none' : ''"
      >
      <app-general-grid
        [values]="elements"
        [columns]="columns"
        [loading]="loading"
        [deviceSize]="getDeviceSize()"
        [totalRecords]="totalRecords"
        [filterable]="true"
        [screenName]="screenName"
        [rows]="rows"
        [lazy]="true"
        (lazyLoad)="lazyLoad($event)"
        [includeExportCSV]="false"
        (changeViewEvent)="changeViewEvent($event)"
        scrollHeight="calc(100vh - 325px)"
        [usePaginator]="true"
        [rowsPerPageOptions]="[50, 100, 200]"
        #grid
        >
      </app-general-grid>
    </div>
  }

  @if (ifDeviceMatches([DeviceSize.XS, DeviceSize.SM, DeviceSize.MD])) {
    <div>
      <p-message
        severity="info"
        text="{{ 'COMMON.LABEL.RESOLUTION_NOT_SUPPORTED' | translate }}"
      ></p-message>
    </div>
  }

  <p-dialog
    [header]="dialogHeader"
    [(visible)]="displayDialog"
    showEffect="fade"
    [modal]="true"
    (onHide)="dialogClosed()"
    [transitionOptions]="'0ms'"
    [style]="{ width: '500px' }"
    >
    <form [formGroup]="form">
      <div class="ui-g ui-fluid" id="company-form">
        <div class="no-padding form">
          <div class="form-item">
            <div class="form-label">{{ "ADMIN.LABEL.NAME" | translate }}:</div>
            <div class="form-input">
              <input
                type="text"
                pInputText
                formControlName="name"
                (change)="nameChanged()"
                />
              </div>
            </div>

            <div class="form-item">
              <div class="form-label">
                {{ "COMMON.LABEL.ABBREVIATION" | translate }}:
              </div>
              <div class="form-input">
                <input
                  type="text"
                  pInputText
                  formControlName="abbreviation"
                  (change)="abbrChanged()"
                  />
                </div>
              </div>

              <div class="form-item">
                <div class="form-label">
                  {{ "COMMON.LABEL.ENABLE" | translate }}:
                </div>
                <div class="form-input">
                  <p-checkbox
                    formControlName="enabled"
                    [binary]="true"
                    (onChange)="toggleRecord($event)"
                  ></p-checkbox>
                </div>
              </div>
            </div>
            @if (showAudit) {
              <div class="no-padding audit">
                <app-audit-dialog-container
                  #audit
                  [showAsDialog]="displayAuditDialog"
                  [auditHistory]="history"
                  [loading]="auditLoading"
                ></app-audit-dialog-container>
              </div>
            }
            <div class="form-buttons">
              @if (canCreate || canEdit) {
                <div class="button-wrapper">
                  <button
                    pButton
                    label="{{ 'COMMON.LABEL.BUTTONS.SAVE' | translate }}"
                    icon="fa fa-fw fa-save"
                    (click)="saveCompany()"
                    [disabled]="!form.valid"
                  ></button>
                </div>
              }
              @if (canDelete === true) {
                <div class="button-wrapper">
                  <button
                    pButton
                    label="{{ 'COMMON.LABEL.BUTTONS.DELETE' | translate }}"
                    icon="fa fa-fw fa-trash"
                    (click)="deleteCompany()"
                  ></button>
                </div>
              }
            </div>
          </div>
        </form>
      </p-dialog>
    </div>
