import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { PickListModule } from 'primeng/picklist';
import { CoreModule } from '../core/core.module';
import { AdminRoutingModule } from './admin-routing.module';
import { EmailGroupContainerComponent } from './containers/email-group-container.component';
import { JobRolesContainerComponent } from './containers/job-roles-container.component';
import { MonLogTypesContainerComponent } from './containers/mon-log-types-container.component';
import { ShiftMitigationContainerComponent } from './containers/shift-mitigation-container.component';
import { CompanyContainerComponent } from './containers/company-container.component';
import { MocEventCategoryContainerComponent } from './containers/moc-event-category-container.component';
import { NoteTypesContainerComponent } from './containers/note-types-container/note-types-container.component';
import { RoleClaimAssignContainerComponent } from './containers/roles/role-claim-container/role-claim-assign-container.component';
import { RoleBusinessUnitAssignContainerComponent } from './containers/roles/role-business-units-container/role-business-unit-assign-container.component';
import { ClaimRoleAssignContainerComponent } from './containers/roles/claim-role-container/claim-role-assign-container.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

@NgModule({
  imports: [
    CommonModule,
    AdminRoutingModule,
    CoreModule,
    PickListModule,
    ConfirmDialogModule,
    TranslateModule.forChild({}),
  ],
  declarations: [
    EmailGroupContainerComponent,
    MonLogTypesContainerComponent,
    JobRolesContainerComponent,
    ShiftMitigationContainerComponent,
    CompanyContainerComponent,
    MocEventCategoryContainerComponent,
    NoteTypesContainerComponent,
    RoleClaimAssignContainerComponent,
    ClaimRoleAssignContainerComponent,
    RoleBusinessUnitAssignContainerComponent,
  ],
  exports: [
    EmailGroupContainerComponent,
    MonLogTypesContainerComponent,
    JobRolesContainerComponent,
    ShiftMitigationContainerComponent,
    CompanyContainerComponent,
    MocEventCategoryContainerComponent,
    NoteTypesContainerComponent,
    RoleClaimAssignContainerComponent,
    ClaimRoleAssignContainerComponent,
    RoleBusinessUnitAssignContainerComponent,
  ],
})
export class AdminModule {}
