import { ChangeDetectorRef, Component, OnInit, OnDestroy, viewChild, inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { forkJoin, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AuditDialogContainerComponent } from 'src/app/core/containers/audit-dialog-container.component';
import { SearchContainerComponent } from 'src/app/core/containers/search-container/search-container.component';
import { TabService } from 'src/app/core/services/tab.service';
import { Role } from 'src/app/model/admin/role';
import { AdminApiService } from 'src/app/services/admin-api.service';
import { AdminBuilderService } from 'src/app/services/admin-builder.service';
import { AdminTableService } from 'src/app/services/admin-table.service';
import { AuthService } from 'src/app/services/auth.service';
import { DeviceService } from 'src/app/services/device.service';
import { LoadingService } from 'src/app/services/loading.service';
import { LogAndMessageService } from 'src/app/services/log-and-message.service';
import { isNullOrUndefined } from 'util';
import { ROLES_BUSINESS_UNITS_TABLE_DEFINITION } from 'src/app/admin/definitions/data-table-definitions';
import { BusinessUnit } from 'src/app/model/admin/business-unit';
import { UpdateRoleInput } from 'src/app/model/admin/update-role-input';
import { sortByProperty } from 'src/app/utils/utils';
import { AuthApiService } from 'src/app/services/auth-api.service';
import { CLAIMS } from 'src/app/constants/auth-constants';

@Component({
    selector: 'app-role-business-unit-assign-container',
    templateUrl: './role-business-unit-assign-container.component.html',
    styleUrls: ['./role-business-unit-assign-container.component.scss'],
    standalone: false
})

export class RoleBusinessUnitAssignContainerComponent extends SearchContainerComponent<Role> implements OnInit, OnDestroy {
  protected _deviceService: DeviceService;
  protected _auth: AuthService;
  protected _adminBuilder = inject(AdminBuilderService);
  private _adminApi = inject(AdminApiService);
  protected _translateService = inject(TranslateService);
  protected _logAndMessage = inject(LogAndMessageService);
  protected _tabService = inject(TabService);
  protected _adminTableService = inject(AdminTableService);
  private _fb = inject(UntypedFormBuilder);
  protected _cdRef: ChangeDetectorRef;
  protected _loader: LoadingService;
  private _confirmationService = inject(ConfirmationService);
  private _authApi = inject(AuthApiService);


  readonly audit = viewChild<AuditDialogContainerComponent>('audit');

  canEdit = false;
  saving = false;
  roleBusinessUnitForm: UntypedFormGroup;
  displayDialog = false;
  dialogHeader: string;
  filteredContacts: any[];

  rolesAndBusinessUnits: any[];
  roles: Role[] = [];
  selectedRole: Role;
  showSuccessMessage = true;
  hasLoaded = false;

  activeTabChangedSub: Subscription;

  availableBusinessUnits: BusinessUnit[] = [];
  sourcePicklist: BusinessUnit[] = [];
  targetPicklist: BusinessUnit[] = [];

  constructor() {
    const _deviceService = inject(DeviceService);
    const _auth = inject(AuthService);
    const _cdRef = inject(ChangeDetectorRef);
    const _loader = inject(LoadingService);

    super(_deviceService, _loader, _auth, _cdRef);
    this._deviceService = _deviceService;
    this._auth = _auth;
    this._cdRef = _cdRef;
    this._loader = _loader;


  }

  ngOnInit() {
    this._adminTableService.setLastLazyLoad(undefined);
    this.setTableService(this._adminTableService);
    this.roleBusinessUnitForm = this._fb.group({
      id: [null],
      name: [{value: null, disabled: true}, Validators.required],
      businessUnits: [{value: false, disabled: true}],
      version: [null]
    });

    if (this._loader.isLoaded()) {
      this.initialize();
    } else {
      this._loader.loadingFinishedEvent.pipe(take(1)).subscribe(() => {
        this.initialize();
      });
    }
  }

  ngOnDestroy(): void {
    if (this.activeTabChangedSub) {
      this.activeTabChangedSub.unsubscribe();
    }
  }

  initialize() {
    const screenName$ = this._translateService.get('ROLE.SCREEN.ROLE_BUSINESS_UNIT_ASSIGN');
    const dialogHeader$ = this._translateService.get('ROLE.LABEL.ROLE_DETAILS');
    forkJoin([screenName$, dialogHeader$]).subscribe((label) => {
      TabService.getInstance().updateActiveTabLabel(label[0]);
      this.screenName = label[0];
      this.dialogHeader = label[1];
    });

    this._adminApi.getAvailableBusinessUnits().pipe(take(1)).subscribe(({ data }) => {
      const clone = { ...data };
      this.availableBusinessUnits = clone.getAvailableBusinessUnits;
    });

    this.canEdit = this._authApi.doesUserHaveAllClaimsFromList([CLAIMS.ADMINISTRATION.ROLES.EDIT_ROLES]);

    if (this.canEdit) {
      this.roleBusinessUnitForm.enable();
    }

    this.columns = ROLES_BUSINESS_UNITS_TABLE_DEFINITION;
    this.applyPreviousData();
    this.applyPreviousFilters();
  }

  handleLazyLoad(req, $event, filters) {
    const query = {
      role: null,
      businessUnit: null
    };

    if (filters.name) { query.role = filters.name.value; }
    if (filters.businessUnits) { query.businessUnit = filters.businessUnits.value; }

    this.queryNetwork(req, $event, query);
  }

  queryNetwork(req, $event, query) {
    req.page += 1;
    const sort = {};
    if ($event.lazy && $event.lazy.sortField) {
      sort[$event.lazy.sortField] = ($event.lazy.sortOrder && $event.lazy.sortOrder === 1) ? 'DESC' : 'ASC';
    } else {
      sort['role'] = 'ASC';
    }

    this._adminTableService.setLastSortField('role');
    this._adminTableService.setLastSortDirection(1);

    this._adminApi.queryForRolesAndBusinessUnits(req.pageSize, req.page, query, sort).pipe(take(1)).subscribe(({data}) => {
      const clone = Object.assign({}, data);
      this.rolesAndBusinessUnits = clone.queryForRolesAndBusinessUnits.items;
      this.totalRecords = clone.queryForRolesAndBusinessUnits.totalRecords;
      this.elements = [...this.rolesAndBusinessUnits];
      this.loading = false;
      this.hasLoaded = true;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  //Double clicking on table row action:
  changeViewEvent($event): void {
    this.selectedRole = $event.data;
    this.roleBusinessUnitForm.patchValue({
      id: $event.data.id,
      name: $event.data.name,
      businessUnits: $event.data.businessUnits,
      version: $event.data.version,
    });

    // Ensure that the name field is not editable
    this.roleBusinessUnitForm.controls['name'].disable();

    // update the sourceBusinessUnits
    // update the targetBusinessUnits
    this.sourcePicklist = [];
    this.targetPicklist = [];
    const roleBusinessUnits = this.roleBusinessUnitForm.controls['businessUnits'].value?.split(',').map(x => x.trim()) ?? [];

    this.availableBusinessUnits.forEach(x => {
      if (roleBusinessUnits.includes(x.name)) {
        this.targetPicklist.push(x);
      }
      else {
        this.sourcePicklist.push(x);
      }
    });

    this.sourcePicklist.sort((a, b) => sortByProperty(a, b, 'name'));
    this.targetPicklist.sort((a, b) => sortByProperty(a, b, 'name'));
    this.displayDialog = true;
  }

  clearForm(): void {
    this.roleBusinessUnitForm.reset();
  }

  dialogClosed(): void {
    this.clearForm();
    this.selectedRole = undefined;
    const audit = this.audit();
    if (!isNullOrUndefined(audit)) {
      audit.closeTogglePanel();
    }
  }

  //refresh button in top right
  refresh(): void {
    this.lazyLoad({ lazy: this._adminTableService.getLastLazyLoad() }); //this triggers infinite loop
  }

  //Save button in dialog popup
  saveRole(): void {
    if (!this.canEdit) {
      return;
    }

    if (!isNullOrUndefined(this.roleBusinessUnitForm) && this.roleBusinessUnitForm.valid) {
      let role = null;
      if (!isNullOrUndefined(this.selectedRole)) {
        role = this.selectedRole;
      } else {
        role = Object.assign({}, this.roleBusinessUnitForm.getRawValue());
      }
      const updateRoleInput: UpdateRoleInput = this._adminBuilder.buildRoleBusinessUnitsInput(role, this.targetPicklist)
      this.saving = true;
      this._adminApi.updateRole(updateRoleInput.id, updateRoleInput).pipe(take(1)).subscribe((result: any) => {
        var updatedRole = this.updateRolesAndBusinessUnits(result.data.updateRole);
        this.changeViewEvent({ data: updatedRole });
        this.clearForm();
        this.displayDialog = false;
        this.saving = false;
      }, (error) => {
        this.saving = false;
        console.error('Error updating role.', error);
      })
    }
  }

  updateRolesAndBusinessUnits(role): Role {
    // find the role in the list
    var index = this.rolesAndBusinessUnits.findIndex(x => x.id == role.id);
    this.rolesAndBusinessUnits[index].businessUnits = role.businessUnits.map(c => c.name).join(', ');
    return this.rolesAndBusinessUnits[index];
  }
}
