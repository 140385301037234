<div id="moc-event-category-container">
  <p-blockUI [blocked]="loading"></p-blockUI>
  @if (ifDeviceMatches([DeviceSize.LG, DeviceSize.XL])) {
    <div
      class="ui-g ui-fluid body"
      >
      <div class="button-wrapper" style="text-align: right">
        <div style="text-align: right">
          <app-progress-spinner [enabled]="saving"></app-progress-spinner>
        </div>
        <div class="button-wrapper group">
          <div id="business-units-wrapper">
            <app-business-unit-filter
              (selectedBusinessUnitsChanged)="handleBusinessUnitChange($event)"
              #buFilter
            ></app-business-unit-filter>
          </div>
          @if (canCreate && hasLoaded) {
            <button
              pButton
              class="width-eighty"
              label="{{ 'COMMON.LABEL.BUTTONS.NEW' | translate }}"
              icon="fa fa-fw fa-plus-circle"
              (click)="newMocEventCategory()"
            ></button>
          }
          <button
            pButton
            class="refresh-button width-twenty"
            icon="fa fa-fw fa-refresh"
            (click)="refresh()"
          ></button>
        </div>
      </div>
    </div>
  }
  <app-progress-spinner [enabled]="!elements"></app-progress-spinner>
  @if (ifDeviceMatches([DeviceSize.LG, DeviceSize.XL])) {
    <div
      [style.display]="!elements ? 'none' : ''"
      >
      <app-general-grid
        [values]="elements"
        [columns]="columns"
        [loading]="loading"
        [deviceSize]="getDeviceSize()"
        [totalRecords]="totalRecords"
        [filterable]="true"
        [screenName]="screenName"
        [rows]="rows"
        [lazy]="true"
        (lazyLoad)="lazyLoad($event)"
        [includeExportCSV]="false"
        (changeViewEvent)="changeViewEvent($event)"
        scrollHeight="calc(100vh - 325px)"
        [usePaginator]="true"
        [rowsPerPageOptions]="[50, 100, 200]"
        #grid
        >
      </app-general-grid>
    </div>
  }

  @if (ifDeviceMatches([DeviceSize.XS, DeviceSize.SM, DeviceSize.MD])) {
    <div>
      <p-message
        severity="info"
        text="{{ 'COMMON.LABEL.RESOLUTION_NOT_SUPPORTED' | translate }}"
      ></p-message>
    </div>
  }

  <p-dialog
    [header]="dialogHeader"
    [(visible)]="displayDialog"
    showEffect="fade"
    [modal]="true"
    (onHide)="dialogClosed()"
    [transitionOptions]="'0ms'"
    [style]="{ width: '750px' }"
    >
    <form [formGroup]="form">
      <div class="ui-g ui-fluid form" id="job-role-form">
        <div class="no-padding form-input">
          <div class="form-input-group">
            <div class="label">{{ "ADMIN.LABEL.NAME" | translate }}:</div>
            <div>
              <input
                type="text"
                pInputText
                formControlName="name"
                (change)="nameChanged()"
                />
              </div>
            </div>
            <div class="form-input-group">
              <div class="label">{{ "COMMON.LABEL.ENABLE" | translate }}:</div>
              <div class="label">
                <p-checkbox
                  formControlName="enabled"
                  [binary]="true"
                  (onChange)="toggleRecord($event)"
                ></p-checkbox>
              </div>
            </div>
          </div>

          <div style="width: 100%">
            <p-pickList
              [style]="{ 'margin-top': '10px' }"
              [source]="sourcePicklist"
              [target]="targetPicklist"
              targetHeader="Assigned Business Units"
              [dragdrop]="false"
              sourceHeader="Available Business Units"
              [responsive]="true"
              [showSourceControls]="false"
              [showTargetControls]="false"
            [sourceStyle]="{
              height: '250px',
              'box-shadow': 'rgba(0, 0, 0, 0.1) 0px 4px 12px'
            }"
            [targetStyle]="{
              height: '250px',
              'box-shadow': 'rgba(0, 0, 0, 0.1) 0px 4px 12px'
            }"
              [disabled]="form.disabled"
              >
              <ng-template let-businessUnit pTemplate="item">
                <div>
                  <div>
                    <h5>{{ businessUnit.name }}</h5>
                  </div>
                </div>
              </ng-template>
            </p-pickList>
          </div>

          <!-- <div class=" no-padding"> Add back later when MOC Turnovers use this.
          <div class=" label">{{'CRM.LABEL.SHIFT_GROUP' | translate}}:</div>
          <div >
            <p-dropdown [options]="groups"
              [autoWidth]="false"
              formControlName="shiftGroup"
            (onChange)="groupChanged($event)"></p-dropdown>
          </div>
        </div> -->
        @if (showAudit) {
          <div class="no-padding panel">
            <app-audit-dialog-container
              #audit
              [showAsDialog]="displayAuditDialog"
              [auditHistory]="history"
              [loading]="auditLoading"
            ></app-audit-dialog-container>
          </div>
        }
        <div class="form-buttons">
          <div>
            @if (canCreate || canEdit) {
              <button
                pButton
                label="{{ 'COMMON.LABEL.BUTTONS.SAVE' | translate }}"
                icon="fa fa-fw fa-save"
                (click)="saveMocEventCategory()"
                [disabled]="!this.form.valid"
              ></button>
            }
          </div>
          <div>
            @if (canDelete) {
              <button
                pButton
                label="{{ 'COMMON.LABEL.BUTTONS.DELETE' | translate }}"
                icon="fa fa-fw fa-trash"
                (click)="deleteMocEventCategory()"
              ></button>
            }
          </div>
        </div>
      </div>
    </form>
  </p-dialog>
</div>
