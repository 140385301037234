<div id="email-group-container">
  <p-blockUI [blocked]="loading === true"></p-blockUI>
  @if (hasLoaded) {
    <div class="content">
      @if (ifDeviceMatches([DeviceSize.LG, DeviceSize.XL])) {
        <div
          class="action-items"
          >
          <div class="action-items" style="text-align: right">
            <div style="text-align: right">
              <app-progress-spinner [enabled]="saving"></app-progress-spinner>
            </div>
            <div id="business-units-wrapper">
              <app-business-unit-filter
                (selectedBusinessUnitsChanged)="handleBusinessUnitChange()"
                #buFilter
              ></app-business-unit-filter>
            </div>
            @if (canCreate) {
              <div class="button-wrapper group">
                <button
                  pButton
                  class="width-eighty"
                  label="{{ 'COMMON.LABEL.BUTTONS.NEW' | translate }}"
                  icon="fa fa-fw fa-plus-circle"
                  (click)="newEmailGroup()"
                ></button>
                <button
                  pButton
                  class="refresh-button width-twenty"
                  icon="fa fa-fw fa-refresh"
                  (click)="refresh()"
                ></button>
              </div>
            }
          </div>
        </div>
      }
      <app-progress-spinner [enabled]="!elements"></app-progress-spinner>
      @if (ifDeviceMatches([DeviceSize.LG, DeviceSize.XL])) {
        <div
          [style.display]="!elements ? 'none' : ''"
          >
          <app-general-grid
            [values]="elements"
            [columns]="columns"
            [loading]="loading"
            [deviceSize]="getDeviceSize()"
            [totalRecords]="totalRecords"
            [filterable]="true"
            [screenName]="screenName"
            [rows]="rows"
            [lazy]="true"
            (lazyLoad)="lazyLoad($event)"
            [includeExportCSV]="false"
            (changeViewEvent)="changeViewEvent($event)"
            scrollHeight="calc(100vh - 300px)"
            [usePaginator]="true"
            [rowsPerPageOptions]="[50, 100, 200]"
            #grid
            >
          </app-general-grid>
        </div>
      }
      <p-dialog
        header="Email Group Details"
        [(visible)]="displayDialog"
        showEffect="fade"
        [modal]="true"
        (onHide)="dialogClosed()"
        [transitionOptions]="'0ms'"
        [style]="{ width: '750px' }"
        >
        <form [formGroup]="emailGroupForm">
          <div id="email-group-form">
            <div class="email-action-buttons">
              @if (canDelete === true) {
                <div class="button-wrapper">
                  <button
                    pButton
                    label="{{ 'COMMON.LABEL.BUTTONS.DELETE' | translate }}"
                    icon="fa fa-fw fa-trash"
                    (click)="deleteEmailGroup()"
                  ></button>
                </div>
              }
              @if (canEdit || canCreate) {
                <div class="button-wrapper">
                  <button
                    pButton
                    label="{{ 'COMMON.LABEL.BUTTONS.SAVE' | translate }}"
                    icon="fa fa-fw fa-save"
                    (click)="saveEmailGroup()"
                    [disabled]="!this.emailGroupForm.valid"
                  ></button>
                </div>
              }
            </div>
            <div class="no-padding form-row">
              <div class="form-row-item">
                <div class="label">{{ "ADMIN.LABEL.NAME" | translate }}:</div>
                <div>
                  <input
                    type="text"
                    pInputText
                    formControlName="name"
                    (change)="nameChanged()"
                    />
                  </div>
                </div>
                <div class="form-row-item">
                  <div class="label">{{ "COMMON.LABEL.ENABLE" | translate }}:</div>
                  <div class="label">
                    <p-checkbox
                      formControlName="enabled"
                      [binary]="true"
                      (onChange)="toggleGroup($event)"
                    ></p-checkbox>
                  </div>
                </div>
              </div>
              <div style="width: 100%">
                <p-pickList
                  [style]="{ 'margin-bottom': '10px' }"
                  [source]="sourcePicklist"
                  [target]="targetPicklist"
                  targetHeader="Assigned Business Units"
                  [dragdrop]="false"
                  sourceHeader="Available Business Units"
                  [responsive]="true"
                  [showSourceControls]="false"
                  [showTargetControls]="false"
              [sourceStyle]="{
                height: '250px',
                'box-shadow': 'rgba(0, 0, 0, 0.1) 0px 4px 12px'
              }"
              [targetStyle]="{
                height: '250px',
                'box-shadow': 'rgba(0, 0, 0, 0.1) 0px 4px 12px'
              }"
                  [disabled]="emailGroupForm.disabled"
                  >
                  <ng-template let-businessUnit pTemplate="item">
                    <div>
                      <div>
                        <h5>{{ businessUnit.name }}</h5>
                      </div>
                    </div>
                  </ng-template>
                </p-pickList>
              </div>
              @if (canEdit === true) {
                <div class="no-padding form-row">
                  <div class="form-row-item">
                    <div class="label">
                      {{ "CONTACT.LABEL.CONTACT" | translate }}:
                    </div>
                    <div>
                      <p-autoComplete
                        [suggestions]="contacts"
                        (completeMethod)="memberSearch($event)"
                        (onSelect)="contactSelected($event)"
                        formControlName="contact"
                        [dropdown]="true"
                        field="fullName"
                        appendTo="body"
                      ></p-autoComplete>
                    </div>
                  </div>
                </div>
              }
              @if (canEdit === true) {
                <div class="no-padding form-row">
                  <div class="form-row-item">
                    <div class="label">{{ "ADMIN.LABEL.EMAIL" | translate }}:</div>
                    <div>
                      <input type="text" pInputText formControlName="emailAddress" />
                    </div>
                  </div>
                </div>
              }
              <div class="form-row-actions">
                @if (canEdit === true) {
                  <div>
                    <button
                      pButton
                      label="{{ 'COMMON.LABEL.BUTTONS.ADD' | translate }}"
                      icon="fa fa-fw fa-plus-circle"
                      (click)="addEmailGroupMember()"
                    ></button>
                  </div>
                }
              </div>
              <div>
                <div class="header-label">
                  {{ "ADMIN.LABEL.EMAIL_GROUP_MEMBERS" | translate }}:
                </div>
                @if (showMemberList()) {
                  <div>
                    <p-listbox
                      [options]="members"
                      (onChange)="memberSelected($event)"
                      [filter]="true"
                      [listStyle]="{ 'max-height': '375px' }"
                      optionLabel="address"
                    ></p-listbox>
                  </div>
                }
                @if (!showMemberList()) {
                  <div>
                    {{ "ADMIN.MESSAGES.INFO.NO_EMAIL_GROUP_MEMBERS" | translate }}
                  </div>
                }
                @if (showMemberList() && canEdit === true) {
                  <div
                    class="button-wrapper"
                    [style]="'height: 40px; margin: 8px 0;'"
                    >
                    <button
                      pButton
                      label="{{ 'COMMON.LABEL.BUTTONS.REMOVE' | translate }}"
                      icon="fa fa-fw fa-minus-circle"
                      (click)="deleteEmailGroupMember()"
                    ></button>
                  </div>
                }
              </div>
            </div>
          </form>
        </p-dialog>
      </div>
    }
    @if (ifDeviceMatches([DeviceSize.XS, DeviceSize.SM, DeviceSize.MD])) {
      <div>
        <p-message
          severity="info"
          text="{{ 'COMMON.LABEL.RESOLUTION_NOT_SUPPORTED' | translate }}"
        ></p-message>
      </div>
    }
  </div>
