import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  viewChild,
  inject,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { forkJoin, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { SearchContainerComponent } from 'src/app/core/containers/search-container/search-container.component';
import { TabService } from 'src/app/core/services/tab.service';
import { DirtyStatus } from 'src/app/model/common/dirty-status';
import { AdminBuilderService } from 'src/app/services/admin-builder.service';
import { AdminTableService } from 'src/app/services/admin-table.service';
import { AdminApiService } from 'src/app/services/admin-api.service';
import { AuthService } from 'src/app/services/auth.service';
import { DeviceService } from 'src/app/services/device.service';
import { LoadingService } from 'src/app/services/loading.service';
import { LogAndMessageService } from 'src/app/services/log-and-message.service';
import { NOTE_TYPES_TABLE_DEFINITION } from '../../definitions/data-table-definitions';
import { BusinessUnit } from 'src/app/model/admin/business-unit';
import { CategoryObject, NoteType } from 'src/app/model/crm/note-type';
import { NoteTypeInput } from 'src/app/model/admin/note-type-input';
import { isValidNoteTypeInput } from 'src/app/crm/functions/note-type-functions';
import { NoteTypeApiService } from 'src/app/services/note-type-api.service';
import { AuthApiService } from 'src/app/services/auth-api.service';
import { BusinessUnitFilterComponent } from 'src/app/core/components/business-unit-filter/business-unit-filter.component';
import { isNullOrUndefined } from 'src/app/utils/utils';
import { CLAIMS } from 'src/app/constants/auth-constants';

@Component({
  selector: 'app-note-types-container',
  templateUrl: './note-types-container.component.html',
  styleUrls: ['./note-types-container.component.scss'],
  standalone: false,
})
export class NoteTypesContainerComponent
  extends SearchContainerComponent<NoteType>
  implements OnInit, OnDestroy
{
  protected _deviceService: DeviceService;
  protected _auth: AuthService;
  protected _noteTypeApi = inject(NoteTypeApiService);
  protected _adminBuilder = inject(AdminBuilderService);
  protected _translateService = inject(TranslateService);
  protected _logAndMessage = inject(LogAndMessageService);
  protected _tabService = inject(TabService);
  protected _adminTableService = inject(AdminTableService);
  private _fb = inject(UntypedFormBuilder);
  protected _cdRef: ChangeDetectorRef;
  protected _loader: LoadingService;
  private _confirmationService = inject(ConfirmationService);
  private _authApi = inject(AuthApiService);
  private _adminApi = inject(AdminApiService);

  readonly businessUnitFilter =
    viewChild<BusinessUnitFilterComponent>('buFilter');
  canCreate = false;
  canEdit = false;
  canDelete = false;
  saving = false;
  form: UntypedFormGroup;
  displayDialog = false;
  dialogHeader: string;

  types: NoteType[] = [];
  categories: CategoryObject[];
  selectedType: NoteType;
  showSuccessMessage = true;
  sub: Subscription;

  activeTabChangedSub: Subscription;

  availableBusinessUnits: BusinessUnit[] = [];
  sourcePicklist: BusinessUnit[] = [];
  targetPicklist: BusinessUnit[] = [];

  constructor() {
    const _deviceService = inject(DeviceService);
    const _auth = inject(AuthService);
    const _cdRef = inject(ChangeDetectorRef);
    const _loader = inject(LoadingService);

    super(_deviceService, _loader, _auth, _cdRef);
    this._deviceService = _deviceService;
    this._auth = _auth;
    this._cdRef = _cdRef;
    this._loader = _loader;
  }

  ngOnInit() {
    this._adminTableService.setLastLazyLoad(undefined);
    this.setTableService(this._adminTableService);
    this.setupActiveTabChangedSubscription();
    this.form = this._fb.group({
      id: [null],
      name: [{ value: null, disabled: false }, Validators.required],
      enabled: [{ value: false, disabled: false }],
      version: [null],
      categories: [{ value: null, disabled: false }, Validators.required],
      businessUnits: [{ value: null, disabled: false }],
    });

    this.columns = NOTE_TYPES_TABLE_DEFINITION;

    const screenName$ = this._translateService.get('CRM.SCREEN.NOTE_TYPES');
    const dialogHeader$ = this._translateService.get(
      'CRM.LABEL.NOTE_TYPE_DETAILS'
    );
    forkJoin([screenName$, dialogHeader$]).subscribe((label) => {
      TabService.getInstance().updateActiveTabLabel(label[0]);
      this.screenName = label[0];
      this.dialogHeader = label[1];
    });

    this._adminApi
      .getAvailableBusinessUnits()
      .pipe(take(1))
      .subscribe(({ data }) => {
        const clone = { ...data };
        this.availableBusinessUnits = clone.getAvailableBusinessUnits;
      });

    this._noteTypeApi
      .getControllerNoteCategories()
      .subscribe((categories) => (this.categories = categories));

    this.canCreate = this._authApi.doesUserHaveAllClaimsFromList([
      CLAIMS.ADMINISTRATION.NOTE_TYPES.CREATE_NOTE_TYPES,
    ]);
    this.canEdit = this._authApi.doesUserHaveAllClaimsFromList([
      CLAIMS.ADMINISTRATION.NOTE_TYPES.EDIT_NOTE_TYPES,
    ]);
    this.canDelete = this._authApi.doesUserHaveAllClaimsFromList([
      CLAIMS.ADMINISTRATION.NOTE_TYPES.DELETE_NOTE_TYPES,
    ]);

    this.form.disable();

    this.applyPreviousData(); // check into what these do
    this.applyPreviousFilters();
  }

  ngOnDestroy(): void {
    if (this.activeTabChangedSub) {
      this.activeTabChangedSub.unsubscribe();
    }
  }

  handleLazyLoad(req, $event, filters) {
    this._adminTableService.setLastAdminLazyLoad(this.screenName, $event.lazy);
    const query = {
      name: null,
      enabled: null,
      businessUnits: null, //['88b84364-2689-48ea-a809-040627078a0a']
    };

    let selectedBusinessUnits = [];
    const businessUnitFilter = this.businessUnitFilter();
    if (businessUnitFilter?.selectedBusinessUnits) {
      // Handle further refreshes & changes to business unit filter component
      selectedBusinessUnits = businessUnitFilter.selectedBusinessUnits;
    } else {
      // Handle initial page load (before the business unit filter subcomponent exists)
      selectedBusinessUnits = this._authApi
        .getUserBusinessUnits()
        .map((bu) => bu.id);
    }
    query.businessUnits = selectedBusinessUnits;
    if (filters.name) {
      query.name = filters.name.value;
    }
    if (filters.enabled && !isNullOrUndefined(filters.enabled.value)) {
      if (filters.enabled.value) {
        query.enabled = 'true';
      } else {
        query.enabled = 'false';
      }
    }

    this.queryNetwork(req, $event, query);
  }

  handleBusinessUnitChange($event) {
    this.lazyLoad({ lazy: this._adminTableService.getLastLazyLoad() });
  }

  queryNetwork(req, $event, query): void {
    req.page += 1;
    this._noteTypeApi.getNoteTypes(false, query.businessUnits, null).subscribe({
      next: (noteTypes: NoteType[]) => {
        this.types = noteTypes;
        this.elements = [...this.types];
        this.totalRecords = this.types.length;
      },
      error: (error) => {
        this.loading = false;
        this._logAndMessage.errorLogOnly(error);
        this._logAndMessage.translateToErrorMessage({
          bodyKey: 'CRM.MESSAGES.ERROR.QUERY_NOTE_TYPES',
          headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
        });
      },
      complete: () => {
        this.loading = false;
        if (this.showSuccessMessage) {
          this.showSuccessMessage = false;
          this._logAndMessage.translateToSuccessMessage({
            bodyKey: 'CRM.MESSAGES.SUCCESS.QUERY_NOTE_TYPES',
            headerKey: 'COMMON.MESSAGES.HEADERS.SUCCESS',
          });
        }
      },
    });
  }

  changeViewEvent($event): void {
    this.selectedType = $event.data;
    this.form.patchValue({
      id: $event.data.id,
      name: $event.data.name,
      enabled: $event.data.active,
      version: $event.data.version,
      categories: this.categories.filter((cat) =>
        $event.data.categories.some((cat2) => cat.id === cat2.id)
      ),
      businessUnits: $event.data.businessUnits,
    });

    const businessUnits = this.form.controls['businessUnits'].value;
    let currentBusinessUnits = [];
    businessUnits.forEach((x) => {
      currentBusinessUnits.push(x.name);
    });

    this.sourcePicklist = [];
    this.targetPicklist = [];
    this.availableBusinessUnits.forEach((x) => {
      if (currentBusinessUnits.includes(x.name)) {
        this.targetPicklist.push(x);
      } else {
        this.sourcePicklist.push(x);
      }
    });

    if (this.canEdit) {
      this.form.enable();
    } else {
      this.form.disable();
    }
    this.displayDialog = true;
  }

  clearForm(): void {
    this.form.reset();
  }

  dialogClosed(): void {
    this.clearForm();
    this.selectedType = undefined;
  }

  newNoteType(): void {
    this.form.reset();
    this.form.controls['enabled'].patchValue(false, { emitEvent: true });
    this.sourcePicklist = [];
    this.targetPicklist = [];
    this.availableBusinessUnits.forEach((x) => {
      this.sourcePicklist.push(x);
    });
    this.displayDialog = true;
    if (this.canCreate) {
      this.form.enable();
    } else {
      this.form.disable();
    }
  }

  refresh(): void {
    this.lazyLoad({ lazy: this._adminTableService.getLastLazyLoad() });
  }

  nameChanged(): void {
    if (!isNullOrUndefined(this.selectedType)) {
      this.selectedType.name = this.form.controls['name'].value;
    }
  }

  saveNoteType(): void {
    if (!(this.canCreate || this.canEdit)) {
      return;
    }

    if (!isNullOrUndefined(this.form) && this.form.valid) {
      let type = null;
      if (!isNullOrUndefined(this.selectedType)) {
        // Handle edits
        type = this.selectedType;
        type.updatedBy = this._auth.getUserName();
        let formObject = Object.assign({}, this.form.getRawValue());
        type.name = formObject.name;
        type.categories = formObject.categories.map((cat) => cat.id);
        type.active = formObject.enabled;
        type.businessUnits = this.targetPicklist;
      } else {
        // Handle new creations
        type = Object.assign({}, this.form.getRawValue());
        type.createdBy = this._auth.getUserName();
        type.categories = type.categories.map((cat) => cat.id);
        type.active = type.enabled;
      }
      if (!type.id) {
        this.createNoteType(type);
      } else {
        this.updateNoteType(type, true);
      }
    }
  }

  createNoteType(noteType: NoteType) {
    if (!this.canCreate) {
      this._logAndMessage.error(
        'Cannot create note type!',
        'Logged in user does not have appropriate claim.'
      );
      return;
    }

    noteType.dirtyStatus = DirtyStatus.NEW;
    const noteTypeInput: NoteTypeInput = this._adminBuilder.buildNoteTypeInput(
      noteType,
      true,
      this.targetPicklist
    );
    const isValid = isValidNoteTypeInput(noteTypeInput, this.types);
    if (!isNullOrUndefined(isValid)) {
      this._logAndMessage.translateToErrorMessage({
        headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
        bodyKey: isValid,
      });
      return;
    }
    this.saving = true;
    let potentialNoteType;
    this._noteTypeApi
      .createNoteType(noteTypeInput)
      .pipe(take(1))
      .subscribe(
        (result: NoteType) => {
          this.clearForm();
          this.displayDialog = false;
          potentialNoteType = result;
        },
        (error) => {
          this.saving = false;
          this._logAndMessage.errorLogOnly(error);
          this._logAndMessage.translateToErrorMessage({
            bodyKey: 'CRM.MESSAGES.ERROR.CREATE_NOTE_TYPE',
            bodyParams: { name: noteType.name },
            headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
          });
        },
        () => {
          this.saving = false;
          this._logAndMessage.translateToSuccessMessage({
            bodyKey: 'CRM.MESSAGES.SUCCESS.CREATE_NOTE_TYPE',
            bodyParams: { name: noteType.name },
            headerKey: 'COMMON.MESSAGES.HEADERS.SUCCESS',
          });
          this.types.push(potentialNoteType);
          this.totalRecords += 1;
          this.refresh();
        }
      );
  }

  updateNoteType(changes: NoteType, closeDialog: boolean) {
    if (!this.canEdit) {
      this._logAndMessage.error(
        'Cannot update note type!',
        'Logged in user does not have appropriate claim.'
      );
      return;
    }

    changes.dirtyStatus = DirtyStatus.UPDATED;
    const typeChanges: NoteTypeInput = this._adminBuilder.buildNoteTypeInput(
      changes,
      false,
      this.targetPicklist
    );
    const isValid = isValidNoteTypeInput(typeChanges, this.types);
    if (!isNullOrUndefined(isValid)) {
      this._logAndMessage.translateToErrorMessage({
        headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
        bodyKey: isValid,
      });
      return;
    }
    this.saving = true;
    this._noteTypeApi
      .updateNoteType(changes.id, typeChanges)
      .pipe(take(1))
      .subscribe(
        (result) => {
          if (!closeDialog && !isNullOrUndefined(result)) {
            this.changeViewEvent({ data: result.data.updateNoteType });
          } else {
            this.clearForm();
            this.displayDialog = false;
          }
        },
        (error) => {
          this.saving = false;
          this._logAndMessage.errorLogOnly(error);
          this._logAndMessage.translateToErrorMessage({
            bodyKey: 'CRM.MESSAGES.ERROR.UPDATE_NOTE_TYPE',
            bodyParams: { name: changes.name },
            headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
          });
        },
        () => {
          this.saving = false;
          this._logAndMessage.translateToSuccessMessage({
            bodyKey: 'CRM.MESSAGES.SUCCESS.UPDATE_NOTE_TYPE',
            bodyParams: { name: changes.name },
            headerKey: 'COMMON.MESSAGES.HEADERS.SUCCESS',
          });
          this.refresh();
        }
      );
  }

  deleteNoteType(): void {
    if (!this.canDelete) {
      this._logAndMessage.error(
        'Cannot delete note type!',
        'Logged in user does not have appropriate claim.'
      );
      return;
    }

    if (!isNullOrUndefined(this.form)) {
      const noteType = Object.assign({}, this.form.getRawValue());
      let confHeader = null,
        ok = null,
        cancel = null,
        message = null;
      const confHeader$ = this._translateService.get(
        'CRM.MESSAGES.HEADERS.DELETE_NOTE_TYPE'
      );
      const ok$ = this._translateService.get('COMMON.LABEL.BUTTONS.YES');
      const cancel$ = this._translateService.get('COMMON.LABEL.BUTTONS.NO');
      const message$ = this._translateService.get(
        'CRM.MESSAGES.CONFIRMATION.DELETE_NOTE_TYPE'
      );

      forkJoin([confHeader$, ok$, cancel$, message$]).subscribe((messages) => {
        confHeader = messages[0];
        ok = messages[1];
        cancel = messages[2];
        message = messages[3];
      });

      this._confirmationService.confirm({
        header: confHeader,
        icon: 'fa fa-question-circle',
        acceptVisible: true,
        rejectVisible: true,
        acceptLabel: ok,
        rejectLabel: cancel,
        message: message,
        accept: () => {
          this.saving = true;
          if (!isNullOrUndefined(noteType.id)) {
            this._noteTypeApi
              .allowNoteTypeDelete(noteType.id)
              .pipe(take(1))
              .subscribe((canDelete) => {
                const allowed = canDelete.data.allowNoteTypeDelete.value;
                if (!allowed) {
                  this.saving = false;
                  this._logAndMessage.translateToErrorMessage({
                    bodyKey: 'ADMIN.MESSAGES.ERROR.DELETE_NOTE_TYPE',
                    headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
                  });
                } else {
                  this._noteTypeApi
                    .deleteNoteType(
                      noteType.id,
                      this._auth.getUserName(),
                      new Date()
                    )
                    .pipe(take(1))
                    .subscribe(
                      (result) => {
                        this.saving = false;
                        this.clearForm();
                        this.displayDialog = false;
                        this.refresh();
                      },
                      (error) => {
                        this._logAndMessage.errorLogOnly(error);
                        this._logAndMessage.translateToErrorMessage({
                          bodyKey: 'CRM.MESSAGES.ERROR.DELETE_NOTE_TYPE',
                          bodyParams: { name: noteType.name },
                          headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
                        });
                      },
                      () => {
                        this._logAndMessage.translateToSuccessMessage({
                          bodyKey: 'CRM.MESSAGES.SUCCESS.DELETE_NOTE_TYPE',
                          bodyParams: { name: noteType.name },
                          headerKey: 'COMMON.MESSAGES.HEADERS.SUCCESS',
                        });
                      }
                    );
                }
              });
          } else {
            this.displayDialog = false;
          }
        },
        reject: () => {},
      });
    }
  }

  setupActiveTabChangedSubscription(): void {
    this.activeTabChangedSub =
      TabService.getInstance().activeTabChanged.subscribe((tab) => {
        if (!isNullOrUndefined(tab) && tab.header === this.screenName) {
          this._adminTableService.setLastLazyLoad(
            this._adminTableService.getLastAdminLazyLoad(this.screenName)
          );
          this.refresh();
        }
      });
  }
}
