import { Column } from '../../model/common/column';
import { SelectItem } from 'primeng/api';

const trueFalse: SelectItem[] = [
  {
    label: 'Choose',
    value: null,
  },
  {
    label: 'true',
    value: true,
  },
  {
    label: 'false',
    value: false,
  },
];

export const MON_LOG_TYPES_TABLE_DEFINITION: Column[] = [
  {
    field: 'name',
    header: 'MONLOG.LABEL.CALL_TYPE',
    mobileHeader: 'MONLOG.LABEL.CALL_TYPE',
    formatPattern: 'text',
    type: 'input',
    width: '25%',
    sortable: true,
    filterable: true,
    matchMode: 'contains',
  },
  {
    field: 'businessUnitString',
    header: 'COMMON.LABEL.BUSINESS_UNITS',
    mobileHeader: 'COMMON.LABEL.BUSINESS_UNITS',
    formatPattern: 'text',
    type: 'input',
    width: '25%',
    sortable: true,
    filterable: true,
    matchMode: 'contains',
  },
  {
    field: 'enabled',
    header: 'COMMON.LABEL.ENABLED',
    mobileHeader: 'COMMON.LABEL.ENABLED',
    formatPattern: 'text',
    type: 'dropdown',
    width: '25%',
    sortable: true,
    filterable: true,
    options: trueFalse,
    matchMode: 'equals',
  },
  {
    field: 'requiresCRER',
    header: 'MONLOG.LABEL.REQUIRES_CRER',
    mobileHeader: 'MONLOG.LABEL.REQUIRES_CRER',
    formatPattern: 'text',
    type: 'dropdown',
    width: '25%',
    sortable: true,
    filterable: true,
    options: trueFalse,
    matchMode: 'equals',
  },
];

export const ROLES_CLAIMS_TABLE_DEFINITION: Column[] = [
  {
    field: 'name',
    header: 'ROLE.LABEL.NAME',
    mobileHeader: 'ROLE.LABEL.NAME',
    formatPattern: 'text',
    type: 'input',
    width: '35%',
    sortable: false,
    filterable: true,
    matchMode: 'contains',
  },
  {
    field: 'claims',
    header: 'ROLE.LABEL.CLAIMS',
    mobileHeader: 'ROLE.LABEL.CLAIMS',
    formatPattern: 'text',
    type: 'input',
    width: '65%',
    sortable: false,
    filterable: true,
    matchMode: 'contains',
  },
];

export const CLAIMS_ROLES_TABLE_DEFINITION: Column[] = [
  {
    field: 'name',
    header: 'ROLE.LABEL.NAME',
    mobileHeader: 'ROLE.LABEL.NAME',
    formatPattern: 'text',
    type: 'input',
    width: '35%',
    sortable: false,
    filterable: true,
    matchMode: 'contains',
  },
  {
    field: 'roles',
    header: 'ROLE.LABEL.ROLES',
    mobileHeader: 'ROLE.LABEL.ROLES',
    formatPattern: 'text',
    type: 'input',
    width: '65%',
    sortable: false,
    filterable: true,
    matchMode: 'contains',
  },
];

export const ROLES_BUSINESS_UNITS_TABLE_DEFINITION: Column[] = [
  {
    field: 'name',
    header: 'ROLE.LABEL.NAME',
    mobileHeader: 'ROLE.LABEL.NAME',
    formatPattern: 'text',
    type: 'input',
    width: '35%',
    sortable: false,
    filterable: true,
    matchMode: 'contains',
  },
  {
    field: 'businessUnits',
    header: 'ROLE.LABEL.BUSINESS_UNITS',
    mobileHeader: 'ROLE.LABEL.BUSINESS_UNITS',
    formatPattern: 'text',
    type: 'input',
    width: '65%',
    sortable: false,
    filterable: true,
    matchMode: 'contains',
  },
];

export const SHIFT_MITIGATION_TABLE_DEFINITION: Column[] = [
  {
    field: 'name',
    header: 'CRM.LABEL.FATIGUE_MITIGATION',
    mobileHeader: 'CRM.LABEL.FATIGUE_MITIGATION',
    formatPattern: 'text',
    type: 'input',
    width: '40%',
    sortable: true,
    filterable: true,
    matchMode: 'contains',
  },
  {
    field: 'ShiftGroup',
    subField: 'name',
    header: 'CRM.LABEL.SHIFT_GROUP',
    mobileHeader: 'CRM.LABEL.SHIFT_GROUP',
    formatPattern: 'text',
    type: 'input',
    width: '35%',
    sortable: true,
    filterable: true,
    matchMode: 'contains',
  },
  {
    field: 'sortOrder',
    header: 'CRM.LABEL.SORT_ORDER',
    mobileHeader: 'CRM.LABEL.SORT_ORDER',
    formatPattern: 'text',
    type: 'input',
    width: '10%',
    sortable: true,
    filterable: true,
    matchMode: 'contains',
  },
  {
    field: 'enabled',
    header: 'COMMON.LABEL.ENABLED',
    mobileHeader: 'COMMON.LABEL.ENABLED',
    formatPattern: 'text',
    width: '15%',
    sortable: true,
    filterable: true,
    type: 'dropdown',
    options: trueFalse,
    matchMode: 'startsWith',
  },
];

export const COMPANY_TABLE_DEFINITION: Column[] = [
  {
    field: 'name',
    header: 'CONTACT.LABEL.COMPANY',
    mobileHeader: 'CONTACT.LABEL.COMPANY',
    formatPattern: 'text',
    type: 'input',
    width: '45%',
    sortable: true,
    filterable: true,
    matchMode: 'contains',
  },
  {
    field: 'abbreviation',
    header: 'COMMON.LABEL.ABBREVIATION',
    mobileHeader: 'COMMON.LABEL.ABBREVIATION',
    formatPattern: 'text',
    type: 'input',
    width: '40%',
    sortable: true,
    filterable: true,
    matchMode: 'contains',
  },
  {
    field: 'enabled',
    header: 'COMMON.LABEL.ENABLED',
    mobileHeader: 'COMMON.LABEL.ENABLED',
    formatPattern: 'text',
    width: '15%',
    sortable: true,
    filterable: true,
    type: 'dropdown',
    options: trueFalse,
    matchMode: 'startsWith',
  },
];

export const MOC_EVENT_CATEGORY_TABLE_DEFINITION: Column[] = [
  {
    field: 'name',
    header: 'CRM.LABEL.CATEGORY',
    mobileHeader: 'CRM.LABEL.CATEGORY',
    formatPattern: 'text',
    type: 'input',
    width: '40%',
    sortable: true,
    filterable: true,
    matchMode: 'contains',
  },
  {
    field: 'businessUnitString',
    header: 'COMMON.LABEL.BUSINESS_UNITS',
    mobileHeader: 'COMMON.LABEL.BUSINESS_UNITS',
    formatPattern: 'text',
    type: 'input',
    width: '35%',
    sortable: true,
    filterable: true,
    matchMode: 'contains',
  },
  // {
  //   field: 'ShiftGroup',
  //   subField: 'name',
  //   header: 'CRM.LABEL.SHIFT_GROUP',
  //   mobileHeader: 'CRM.LABEL.SHIFT_GROUP',
  //   formatPattern: 'text',
  //   type: 'input',
  //   width: '35%',
  //   sortable: true,
  //   filterable: true,
  //   matchMode: 'contains'
  // },
  {
    field: 'enabled',
    header: 'COMMON.LABEL.ENABLED',
    mobileHeader: 'COMMON.LABEL.ENABLED',
    formatPattern: 'text',
    width: '15%',
    sortable: true,
    filterable: true,
    type: 'dropdown',
    options: trueFalse,
    matchMode: 'startsWith',
  },
];

export const NOTE_TYPES_TABLE_DEFINITION: Column[] = [
  {
    field: 'name',
    header: 'CRM.LABEL.TYPE',
    mobileHeader: 'CRM.LABEL.TYPE',
    formatPattern: 'text',
    type: 'input',
    width: '30%',
    sortable: true,
    filterable: true,
    matchMode: 'contains',
  },
  {
    field: 'categoryString',
    header: 'CRM.LABEL.CATEGORY',
    mobileHeader: 'CRM.LABEL.CATEGORY',
    formatPattern: 'text',
    type: 'input',
    width: '30%',
    sortable: true,
    filterable: true,
    matchMode: 'contains',
  },
  {
    field: 'businessUnitString',
    header: 'COMMON.LABEL.BUSINESS_UNITS',
    mobileHeader: 'COMMON.LABEL.BUSINESS_UNITS',
    formatPattern: 'text',
    type: 'input',
    width: '25%',
    sortable: true,
    filterable: true,
    matchMode: 'contains',
  },
  {
    field: 'active',
    header: 'COMMON.LABEL.ENABLED',
    mobileHeader: 'COMMON.LABEL.ENABLED',
    formatPattern: 'text',
    width: '15%',
    sortable: true,
    filterable: true,
    type: 'dropdown',
    options: trueFalse,
    matchMode: 'equals',
  },
];
