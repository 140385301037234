import { ChangeDetectorRef, Component, OnInit, OnDestroy, viewChild, inject } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AuditDialogContainerComponent } from 'src/app/core/containers/audit-dialog-container.component';
import { SearchContainerComponent } from 'src/app/core/containers/search-container/search-container.component';
import { TabService } from 'src/app/core/services/tab.service';
import { Role } from 'src/app/model/admin/role';
import { AdminApiService } from 'src/app/services/admin-api.service';
import { AdminBuilderService } from 'src/app/services/admin-builder.service';
import { AdminTableService } from 'src/app/services/admin-table.service';
import { AuthService } from 'src/app/services/auth.service';
import { DeviceService } from 'src/app/services/device.service';
import { LoadingService } from 'src/app/services/loading.service';
import { LogAndMessageService } from 'src/app/services/log-and-message.service';
import { CLAIMS_ROLES_TABLE_DEFINITION, ROLES_CLAIMS_TABLE_DEFINITION } from 'src/app/admin/definitions/data-table-definitions';
import { Claim, ClaimConnection, RoleSortOrQuery, UpdateClaimInput } from 'src/app/model/admin/claim';
import { UpdateRoleInput } from 'src/app/model/admin/update-role-input';
import { isNullOrUndefined, sortByProperty } from 'src/app/utils/utils';
import { AuthApiService } from 'src/app/services/auth-api.service';
import { PickList } from 'primeng/picklist';
import { CLAIMS } from 'src/app/constants/auth-constants';


@Component({
    selector: 'app-claim-role-assign-container',
    templateUrl: './claim-role-assign-container.component.html',
    styleUrls: ['./claim-role-assign-container.component.scss'],
    standalone: false
})
export class ClaimRoleAssignContainerComponent
  extends SearchContainerComponent<Claim>
  implements OnInit, OnDestroy
{
  protected _deviceService: DeviceService;
  protected _auth: AuthService;
  protected _adminBuilder = inject(AdminBuilderService);
  private _adminApi = inject(AdminApiService);
  protected _translateService = inject(TranslateService);
  protected _logAndMessage = inject(LogAndMessageService);
  protected _tabService = inject(TabService);
  protected _adminTableService = inject(AdminTableService);
  private _fb = inject(UntypedFormBuilder);
  protected _cdRef: ChangeDetectorRef;
  protected _loader: LoadingService;
  private _authApi = inject(AuthApiService);

  readonly audit = viewChild<AuditDialogContainerComponent>('audit');
  readonly pickList = viewChild<PickList>('pickList');

  canEdit = false;
  saving = false;
  claimRoleForm: UntypedFormGroup;
  displayDialog = false;
  dialogHeader: string;
  filteredContacts: any[];

  claimsAndRoles: any[];
  selectedClaim: Claim;
  showSuccessMessage = true;
  hasLoaded = false;

  activeTabChangedSub: Subscription;

  availableRoles: Role[] = [];
  sourcePicklist: Role[] = [];
  targetPicklist: Role[] = [];

  constructor() {
    const _deviceService = inject(DeviceService);
    const _auth = inject(AuthService);
    const _cdRef = inject(ChangeDetectorRef);
    const _loader = inject(LoadingService);

    super(_deviceService, _loader, _auth, _cdRef);
    this._deviceService = _deviceService;
    this._auth = _auth;
    this._cdRef = _cdRef;
    this._loader = _loader;

  }

  ngOnInit() {
    this._adminTableService.setLastLazyLoad(undefined);
    this.setTableService(this._adminTableService);
    this.claimRoleForm = this._fb.group({
      id: [null],
      name: [{ value: null, disabled: true }, Validators.required],
      roles: [{ value: false, disabled: true }],
      version: [null],
    });

    if (this._loader.isLoaded()) {
      this.initialize();
    } else {
      this._loader.loadingFinishedEvent.pipe(take(1)).subscribe(() => {
        this.initialize();
      });
    }
  }

  ngOnDestroy(): void {
    if (this.activeTabChangedSub) {
      this.activeTabChangedSub.unsubscribe();
    }
  }

  initialize() {
    const screenName$ = this._translateService.get(
      'ROLE.SCREEN.CLAIM_ROLE_ASSIGN'
    );
    const dialogHeader$ = this._translateService.get('ROLE.LABEL.CLAIM_DETAILS');
    forkJoin([screenName$, dialogHeader$]).subscribe((label) => {
      TabService.getInstance().updateActiveTabLabel(label[0]);
      this.screenName = label[0];
      this.dialogHeader = label[1];
    });

    let sort = {};
    sort['role'] = 'ASC';

    this._adminApi
    .queryForRolesAndClaims(100, 1, {}, sort)
    .pipe(take(1))
    .subscribe(
      ({ data }) => {
        const clone = Object.assign({}, data);
        this.availableRoles = clone.queryForRolesAndClaims.items;
      },
      (error) => {
        console.log(error);
        this.loading = false;
      }
    );

    this.canEdit = this._authApi.doesUserHaveAllClaimsFromList([CLAIMS.ADMINISTRATION.ROLES.EDIT_ROLES]);
    if (this.canEdit) {
      this.claimRoleForm.enable();
    }

    this.columns = CLAIMS_ROLES_TABLE_DEFINITION;
    this.applyPreviousData();
    this.applyPreviousFilters();
  }

  handleLazyLoad(req, $event, filters) {
    const query = {
      role: undefined,
      claim: undefined,
    };

    if (filters.name) {
      query.claim = filters.name.value;
    }
    if (filters.roles) {
      query.role = filters.roles.value;
    }

    this.queryNetwork(req, $event, query);
  }

  queryNetwork(req, $event, query) {
    const sort: RoleSortOrQuery = {claim: undefined, role: undefined, businessUnit: undefined};
    if ($event.lazy && $event.lazy.sortField) {
      sort[$event.lazy.sortField] =
        $event.lazy.sortOrder && $event.lazy.sortOrder === 1 ? 'DESC' : 'ASC';
    } else {
      sort['claim'] = 'ASC';
    }

    this._adminTableService.setLastSortField('claim');
    this._adminTableService.setLastSortDirection(1);

    this._adminApi
      .queryForClaimsAndRoles(500, 1, query, sort)
      .pipe(take(1))
      .subscribe(
        (data: ClaimConnection) => {
          this.claimsAndRoles = data.items;
          this.totalRecords = data.totalRecords;
          this.elements = [...this.claimsAndRoles];
          this.loading = false;
          this.hasLoaded = true;
        },
        (error) => {
          console.log(error);
          this.loading = false;
        }
      );
  }

  //Double clicking on table row action:
  changeViewEvent($event): void {
    this.selectedClaim = $event.data;
    this.claimRoleForm.patchValue({
      id: $event.data.id,
      name: $event.data.name,
      roles: $event.data.roles,
      version: $event.data.version,
    });

    // Ensure that the name field is not editable
    this.claimRoleForm.controls['name'].disable();

    // update the sourceRoles
    // update the targetRoles
    this.sourcePicklist = [];
    this.targetPicklist = [];
    const roleClaims =
      this.claimRoleForm.controls['roles'].value
        ?.split(',')
        .map((x) => x.trim()) ?? [];

    this.availableRoles.forEach((x) => {
      if (roleClaims.includes(x.name)) {
        this.targetPicklist.push(x);
      } else {
        this.sourcePicklist.push(x);
      }
    });

    this.sourcePicklist.sort((a, b) => sortByProperty(a, b, 'name'));
    this.targetPicklist.sort((a, b) => sortByProperty(a, b, 'name'));
    this.displayDialog = true;
  }

  clearForm(): void {
    this.claimRoleForm.reset();
    this.pickList().resetSourceFilter();
    this.pickList().resetTargetFilter();
  }

  dialogClosed(): void {
    this.clearForm();
    this.selectedClaim = undefined;
    const audit = this.audit();
    if (!isNullOrUndefined(audit)) {
      audit.closeTogglePanel();
    }
  }

  //refresh button in top right
  refresh(): void {
    this.lazyLoad({ lazy: this._adminTableService.getLastLazyLoad() }); //this triggers infinite loop
  }

  //Save button in dialog popup
  saveClaim(): void {
    if (!this.canEdit) {
      return;
    }

    if (!isNullOrUndefined(this.claimRoleForm) && this.claimRoleForm.valid) {
      const updateClaimInput: UpdateClaimInput =
        this._adminBuilder.buildClaimRoleInput(
          this.claimRoleForm.getRawValue(),
          this.targetPicklist
        );

      this.saving = true;

      console.log(updateClaimInput);

      this._adminApi
        .updateClaim(updateClaimInput)
        .pipe(take(1))
        .subscribe(
          (result: Claim) => {
            var updatedClaim = this.updateClaimsAndRoles(result);
            this.changeViewEvent({ data: updatedClaim });
            this.clearForm();
            this.displayDialog = false;
            this.saving = false;
          },
          (error) => {
            this.saving = false;
            console.error('Error updating claim.', error);
          }
        );
    }
  }

  updateClaimsAndRoles(claim): Claim {
    // find the role in the list
    var index = this.claimsAndRoles.findIndex((x) => x.id == claim.id);
    this.claimsAndRoles[index].roles = claim.roles
      .map((r) => r.name)
      .join(', ');
    return this.claimsAndRoles[index];
  }
}
