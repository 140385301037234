<div id="mon-log-types-container">
  <p-blockUI [blocked]="loading === true"></p-blockUI>
  @if (ifDeviceMatches([DeviceSize.LG, DeviceSize.XL])) {
  <div class="ui-g ui-fluid">
    <div style="text-align: right">
      <div style="text-align: right">
        <app-progress-spinner [enabled]="saving"></app-progress-spinner>
      </div>
    </div>
  </div>
  }
  <app-progress-spinner [enabled]="!elements"></app-progress-spinner>

  @if (ifDeviceMatches([DeviceSize.XS, DeviceSize.SM, DeviceSize.MD])) {
  <div>
    <p-message
      severity="info"
      text="{{ 'COMMON.LABEL.RESOLUTION_NOT_SUPPORTED' | translate }}"
    ></p-message>
  </div>
  } @if (hasLoaded) {
  <div class="data-table">
    <div class="button-wrapper group table-actions">
      <div id="business-units-wrapper">
        <app-business-unit-filter
          (selectedBusinessUnitsChanged)="handleBusinessUnitChange()"
          #buFilter
        ></app-business-unit-filter>
      </div>
      @if (canCreate && ifDeviceMatches([DeviceSize.LG, DeviceSize.XL])) {
      <button
        pButton
        class="width-eighty"
        label="{{ 'COMMON.LABEL.BUTTONS.NEW' | translate }}"
        icon="fa fa-fw fa-plus-circle"
        (click)="newMonLogType()"
      ></button>
      }
      <button
        pButton
        class="refresh-button width-twenty"
        icon="fa fa-fw fa-refresh"
        (click)="refresh()"
      ></button>
    </div>
    @if (ifDeviceMatches([DeviceSize.LG, DeviceSize.XL])) {
    <div [style.display]="!elements ? 'none' : ''">
      <app-general-grid
        [values]="elements"
        [columns]="columns"
        [loading]="loading"
        [deviceSize]="getDeviceSize()"
        [totalRecords]="totalRecords"
        [filterable]="true"
        [screenName]="screenName"
        [rows]="rows"
        [lazy]="true"
        (lazyLoad)="lazyLoad($event)"
        [includeExportCSV]="false"
        (changeViewEvent)="changeViewEvent($event)"
        scrollHeight="calc(100vh - 325px)"
        [usePaginator]="true"
        [rowsPerPageOptions]="[50, 100, 200]"
        #grid
      >
      </app-general-grid>
    </div>
    }
  </div>
  }

  <p-dialog
    [header]="dialogHeader"
    [(visible)]="displayDialog"
    showEffect="fade"
    [modal]="true"
    (onHide)="dialogClosed()"
    [transitionOptions]="'0ms'"
    [style]="{ width: '750px' }"
  >
    <form [formGroup]="monLogTypeForm">
      <div class="ui-g ui-fluid" id="email-group-form">
        <div class="no-padding info-header">
          <div class="row-item">
            <div class="label">{{ "ADMIN.LABEL.NAME" | translate }}:</div>
            <div>
              <input
                type="text"
                pInputText
                formControlName="name"
                (change)="nameChanged()"
              />
            </div>
          </div>

          <div class="row-item">
            <div class="label">{{ "COMMON.LABEL.ENABLE" | translate }}:</div>
            <div class="label">
              <p-checkbox
                formControlName="enabled"
                [binary]="true"
                (onChange)="toggleRecord($event)"
              ></p-checkbox>
            </div>
          </div>

          <div class="row-item">
            <div class="label">
              {{ "MONLOG.LABEL.REQUIRES_CRER" | translate }}:
            </div>
            <div class="label">
              <p-checkbox
                formControlName="requiresCRER"
                [binary]="true"
                (onChange)="toggleRecord($event, true)"
              ></p-checkbox>
            </div>
          </div>
        </div>

        <div class="row-item" style="width: 100%">
          <p-pickList
            [source]="sourcePicklist"
            [target]="targetPicklist"
            targetHeader="Assigned Business Units"
            [dragdrop]="false"
            sourceHeader="Available Business Units"
            [responsive]="true"
            [showSourceControls]="false"
            [showTargetControls]="false"
            [sourceStyle]="{
              height: '250px',
              'box-shadow': 'rgba(0, 0, 0, 0.1) 0px 4px 12px'
            }"
            [targetStyle]="{
              height: '250px',
              'box-shadow': 'rgba(0, 0, 0, 0.1) 0px 4px 12px'
            }"
            [disabled]="monLogTypeForm.disabled"
          >
            <ng-template let-businessUnit pTemplate="item">
              <div>
                <div>
                  <h5>{{ businessUnit.name }}</h5>
                </div>
              </div>
            </ng-template>
          </p-pickList>
        </div>

        <div class="no-padding" style="margin-bottom: 8px">
          <app-audit-dialog-container
            [showAsDialog]="displayAuditDialog"
            [auditHistory]="history"
            [loading]="auditLoading"
            #audit
          ></app-audit-dialog-container>
        </div>
        <div>
          @if (canCreate || canEdit) {
          <div class="button-wrapper">
            <button
              pButton
              label="{{ 'COMMON.LABEL.BUTTONS.SAVE' | translate }}"
              icon="fa fa-fw fa-save"
              (click)="saveMonLogType()"
              [disabled]="!this.monLogTypeForm.valid"
            ></button>
          </div>
          } @if (canEdit === true) {
          <div class="button-wrapper">
            <button
              pButton
              label="{{ 'COMMON.LABEL.BUTTONS.DELETE' | translate }}"
              icon="fa fa-fw fa-trash"
              (click)="deleteMonLogType()"
            ></button>
          </div>
          }
        </div>
      </div>
    </form>
  </p-dialog>
</div>
